<template>
    <div class="insurance-products">
        <insurance-products-filter-block :is-load-xml="false"
                              :is-load-data="true"
                              :orders="[]"
                              @init-orders-list="initList()"
                              ref="filter"></insurance-products-filter-block>
        <div class="container insurance-history__wrapp" ref="table">
            <div v-if="isLoad">
                <row-skeleton v-for="index in 4" :key="index"></row-skeleton>
            </div>
            <div v-else>
                <div v-for="(company, i) in companiesList" class="insurance-history__list">
                    <div class="insurance-history__list__preview collapsed" data-toggle="collapse" :data-target="'#tariffs' + i" aria-expanded="false" :aria-controls="'tariffs' + i">
                        <div class="logo">
                            <div v-if="company.companyInfo.logo">
                                <img v-if="company.companyInfo.logo" :src="company.companyInfo.logo" :alt="company.companyInfo.publicName">
                                <span v-text="' - '+company.companyInfo.publicName"></span>
                            </div>

                            <span v-else v-text="company.companyInfo.publicName"></span>
                        </div>
                        <div class="arrow">
                            <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.2795 2.75059e-08L11 0.69496L5.5 6L-2.31891e-07 0.694961L0.7205 4.45343e-07L5.50275 4.61273L10.2795 2.75059e-08Z" fill="#009BEB"/>
                            </svg>
                        </div>
                    </div>

                    <div class="insurance-history__list__container">
                        <div :id="'tariffs' + i" class="insurance-history__list__content show-plus-icon collapse">
                            <div class="insurance-history__nav">
                                <span class="insurance-history__nav__item">Тип продукту</span>
                                <span v-if="![4,5].includes(company.type_id)"  class="insurance-history__nav__item">Тип користувача</span>
                                <span v-if="![2,3,4,5].includes(company.type_id)" class="insurance-history__nav__item">ОТК</span>
                                <span class="insurance-history__nav__item">Комісія, %</span>
                                <span v-if="![0,2,4].includes(company.type_id)" class="insurance-history__nav__item">Зона<br> реєстрації ТЗ</span>
                                <span v-else-if="[2].includes(company.type_id)" class="insurance-history__nav__item">Зона покриття</span>
                                <span v-if="![2,3,5].includes(company.type_id)" class="insurance-history__nav__item">Франшиза</span>
                                <span v-if="![1,2,4,5].includes(company.type_id)" class="insurance-history__nav__item">Покрытие</span>
                                <span v-else-if="[4].includes(company.type_id)" class="insurance-history__nav__item">Страховая сумма</span>
                                <span v-if="![3,4].includes(company.type_id)" class="insurance-history__nav__item">Тип ТЗ</span>
                                <span v-if="[2].includes(company.type_id)" class="insurance-history__nav__item">Період</span>
                                <span v-if="![2,3].includes(company.type_id)" class="insurance-history__nav__item">Программа</span>
                            </div>
                            <div v-if="isShowDgoTariff(company.tariffDgo)" class="insurance-history__product">
                              <div class="insurance-history__list__child" :data-toggle="'collapse'" :aria-controls="'tariffDgo' + i" aria-expanded="false" :data-target="'#tariffDgo' + i">
                                    <div class="insurance-history__list__item">ДЦВ</div>
                                    <div class="insurance-history__list__item"></div>
                                    <div class="insurance-history__list__item"></div>
                                    <div class="insurance-history__list__item percent disabled">
                                        {{ showTariffDgoCommission(company.tariffDgo) }}  <i style="margin-left: 5px;" v-b-tooltip="'комісія при оформленні ДЦВ'">?</i>
                                    </div>
                                    <div class="insurance-history__list__item"></div>
                                    <div class="insurance-history__list__item"></div>
                                    <div class="insurance-history__list__item"></div>
                                </div>

                                <div :id="'tariffDgo' + i" class="insurance-history__product__list collapse">
                                    <div class="insurance-history__product__user">
                                        <div class="insurance-history__product__user__list">
                                            <ul>
                                                <li v-for="partner in partnersList">
                                                    <div class="row">
                                                        <div style="width: 37%">
                                                            <label class="not-checkbox"  :for="'partnerDgoProduct' + partner.id">
                                                                {{ partner.fullName }}
                                                            </label>
                                                        </div>
                                                        <div style="width: auto; margin-right: 25px" class="insurance-history__list__item percent">
                                                            <span class="descr">Комісія:</span>
                                                            <span v-if="! isLandingUserType(partner.typeId)"
                                                                @click="changeDgoCommission(partner, company.tariffDgo)"
                                                                style="cursor:pointer">
                                                                <b>{{getPartnerCommission(company.tariffDgo.childDgoTariffSettings, partner.id)}}</b>
                                                            </span>
                                                            <span v-else style="background: #f6f7fb;">
                                                                <b>{{getPartnerCommission(company.tariffDgo.childDgoTariffSettings, partner.id)}}</b>
                                                            </span>
                                                            <i v-b-tooltip="'Комісія при оформленні ДЦВ'">?</i>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="isShowTariff(tariff)" v-for="(tariff, index) in company.tariffs" class="insurance-history__product">
                                <div class="insurance-history__list__child" :data-toggle="isMainUser(tariff) ? 'collapse' : ''" :aria-controls="'tariff' + index + i" aria-expanded="false" :data-target="'#tariff' + index + i">
                                    <div class="insurance-history__list__item">{{ showCompanyType(company.type_id, company.companyType) }}</div>
                                    <div v-if="![4,5].includes(company.type_id)" class="insurance-history__list__item">{{ getUserType(tariff, company.companyType) }}</div>
                                    <div v-if="![2,3,4,5].includes(company.type_id)" class="insurance-history__list__item">{{ getOkt(tariff, company.companyType) }}</div>
                                    <div  class="insurance-history__list__item percent">
                                        <div v-if="isShowCommission(tariff)">
                                           {{tariff.commission}}  <i style="margin-left: 5px;" v-b-tooltip="'Комісія'">?</i>
                                        </div>
                                    </div>
                                    <div v-if="![0,4].includes(company.type_id)" class="insurance-history__list__item">{{getZone(tariff) }}</div>
                                    <div v-if="![2,3,5].includes(company.type_id)" class="insurance-history__list__item">{{ getFranchise(tariff) }}</div>
                                    <div v-if="![1,2,5].includes(company.type_id)" class="insurance-history__list__item">{{ getCoverage(tariff) }}</div>
                                    <div v-if="![3,4].includes(company.type_id)" class="insurance-history__list__item">{{ getAutoType(tariff)}}</div>
                                    <div v-if="[2].includes(company.type_id)" class="insurance-history__list__item">{{ getPeriodType(tariff)}}</div>
                                    <div v-if="[1,4,5].includes(company.type_id)" class="insurance-history__list__item">{{ getProgrammType(tariff)}}</div>
                                    <div v-if="!isShowCommission(tariff)" class="partners__preview__item btns">
                                        <button type="button" @click="removeTariff(tariff)" class="delete">
                                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.00293 18C2.00293 19.103 2.89993 20 4.00293 20H14.0029C15.1059 20 16.0029 19.103 16.0029 18V6H18.0029V4H14.0029V2C14.0029 0.897 13.1059 0 12.0029 0H6.00293C4.89993 0 4.00293 0.897 4.00293 2V4H0.00292969V6H2.00293V18ZM6.00293 2H12.0029V4H6.00293V2ZM5.00293 6H14.0029L14.0039 18H4.00293V6H5.00293Z" fill="#E41A1A"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="insurance-history__product__list collapse" :id="'tariff' + index + i">
                                    <div class="insurance-history__product__user">
                                        <div class="insurance-history__product__user__list">
                                            <ul>
                                                <li v-for="partner in partnersList">
                                                    <div class="row">
                                                        <div style="width: 37%">
                                                            <div v-if="! isLandingUserType(partner.typeId)">
                                                                <input @change="changeStatusTariff($event, partner, tariff)" type="checkbox" :id="'partnerProduct' + index + partner.id + tariff.id" :checked="isCheckPartner(partner.id, tariff.childTariffSettings)">
                                                                <label  :for="'partnerProduct' + index + partner.id + tariff.id">
                                                                    {{ partner.fullName }}
                                                                </label>
                                                            </div>
                                                            <div style v-else>
                                                                <input disabled type="checkbox" :checked="isCheckPartner(partner.id, tariff.childTariffSettings)">
                                                                <label  :for="'partnerProduct' + index + partner.id + tariff.id">
                                                                    {{ partner.fullName }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                      <!--Скрыть скидки-->
                                                        <div v-if="[0].includes(company.type_id)" style="width: auto; margin-right: 25px" class="insurance-history__list__item percent">
                                                            <span class="descr">Знижка:</span>
                                                            <span
                                                                @click="changeCommission(partner, tariff, company.type_id)"

                                                                style="cursor:pointer">
                                                            <b>{{getPartnerDiscount(tariff.childTariffSettings, partner.id)}}</b>
                                                        </span>
                                                            <i v-b-tooltip="'Знижка'">?</i>
                                                        </div>
                                                      <!--Скрыть кешбек-->
                                                      <div v-if="[2].includes(company.type_id)" style="width: auto; margin-right: 25px" class="insurance-history__list__item percent">
                                                        <span class="descr">Кешбек:</span>
                                                        <span
                                                            @click="changeCommission(partner, tariff, company.type_id)"

                                                            style="cursor:pointer">
                                                            <b>{{getPartnerCashBack(tariff.childTariffSettings, partner.id)}}</b>
                                                        </span>
                                                        <i v-b-tooltip="'Кешбек'">?</i>
                                                      </div>
                                                        <div style="width: auto; margin-right: 25px" class="insurance-history__list__item percent">
                                                            <span class="descr">Комісія:</span>

                                                            <span v-if="! isLandingUserType(partner.type_id)"
                                                                @click="changeCommission(partner, tariff, company.type_id)"
                                                                style="cursor:pointer">
                                                                <b>{{getPartnerCommission(tariff.childTariffSettings, partner.id)}}</b>
                                                            </span>
                                                            <span v-else style="background: #f6f7fb;">
                                                                <b>{{getPartnerCommission(tariff.childTariffSettings, partner.id)}}</b>
                                                            </span>

                                                            <i v-b-tooltip="'Комісія при оформленні поліса'">?</i>
                                                        </div>
    <!--                                                    @click="changeCommission(getPartnerCommission(partner.tariffSettings), getPartnerDiscount(partner.tariffSettings), partner, tariff)"-->
                                                    </div>
                                                </li>
                                            </ul>
                                            <!--                                    <button data-toggle="collapse" data-target="#insuranceProducts1" aria-expanded="false" aria-controls="insuranceProducts1">Сохранить</button>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <penalty-setting></penalty-setting>
            </div>
        </div>
        <update-commission-modal @initList="initList" :discount="updateDiscount" :cashback="updateCashBack"
                                 :commission="updateCommission" :updateTariffData="updateTariffData" :typeId="typeId"></update-commission-modal>
        <update-dgo-commission-modal @refreshCompaniesList="refreshCompaniesList"
                                     :commission="updateDgoCommission"
                                     :updateTariffData="updateTariffDgoData">
        </update-dgo-commission-modal>
    </div>

</template>

<script>

import PenaltySetting from "@/views/insuranceProducts/componets/PenaltySetting.vue";

$(document).on('click', '.accountCashHistoryFilter-box .dropdown-menu', function (e) {
    $(this).hasClass('show') && e.stopPropagation();
});

import { getCompanySettingsList, updateCompanySettings,  } from '../../api/company';
import { createUserTariff, updateUserTariff} from '../../api/tariff';
import { default as InsuranceProductsFilterBlock } from "./filterBlock";

import {userMixin} from '@/mixin';
import rowSkeleton from './componets/RowSkeleton';
import updateCommissionModal from './updateCommissionModal';
import updateDgoCommissionModal from './updateDgoCommissionModal';

export default {
    components: {PenaltySetting, updateCommissionModal, updateDgoCommissionModal, rowSkeleton, InsuranceProductsFilterBlock },
    mixins: [userMixin],
    data: function () {
        return {
            companyType: {},
            isLoad: true,
            editCompany: {},
            statusCompany: 0,
            submitForm: false,
            companiesList: [],
            showCompanies: true,
            updateCompany: false,
            activeClassWrapp: "",
            updateCommission: 0,
            updateDgoCommission: 0,
            updateDiscount: 0,
            updateCashBack: 0,
            updateTariffData: {},
            updateTariffDgoData: {},
            typeId: 0
        };
    },

    created() {
        this.refreshCompaniesList(true);
        this.errors = null;
        this.isPartner = this.$store.getters.IS_PARTNER_STATUS
    },

    mounted() {
        if (! this.partnersList) {
            this.fetchPartnersList();
        }
    },

    computed : {
        partnersList() {
            return this.$store.getters.ALL_PARTNERS;
        },
        /*isPartner: function () {
            return this.$store.getters.IS_PARTNER_STATUS;
        },*/
    },

    serverPrefetch () {
        return this.fetchPartnersList();
    },

    watch: {

    },

    methods: {
        checkIssetLandingTariff(tariffs, partnerId) {
            return this.$_.findWhere(tariffs,{ userId: partnerId })?.id || false;
        },
        isShowDgoTariff(tariffDgo) {
            return false; // не показываем ДГО в компании с Осаго
            return tariffDgo?.commission > 0;
        },
        showCompanyType(type_id, companyType) {
            if (companyType === 'morphBusCompany') {
                return 'Автобус'
            }
            return this.$_.findWhere(this.$store.getters.START_COMPANY_TYPE, { id: type_id })?.name;
        },
        showTariffDgoCommission(tariffDgo) {
            return tariffDgo?.commission || 0;
        },
        isMainUser() {
            return !!! this.$store.getters.USER?.user_parent_id;
        },

        changeDgoCommission(partner, tariff) {
            let data = {
                partnerId: partner.id,
                isActive: event.target.checked,
                tariffId: tariff.id,
            };


            this.updateDgoCommission = this.$_.findWhere(tariff.childDgoTariffSettings, { userId: partner.id })?.commission || 0;
            this.updateTariffDgoData = data;

            //TODO нужно будет с модальным окном решить что то, bootstrap vus не работает из-за конфиликта с простым bootstrap
            $('#updateDgoCommissionModal').modal('show');
        },

        changeCommission(partner, tariff, typeId) {
            //TODO вынести в отдельный метод. Где будет формировать нужный набор данныч. ЧТо бы уйти от дубля кода
            let data = {
                partnerId: partner.id,
                partnerTypeId: partner.typeId,
                isActive: event.target.checked,
                tariffId: tariff.id,
            };

            this.updateCommission = this.$_.findWhere(tariff.childTariffSettings, { userId: partner.id })?.commission || 0;
            this.updateDiscount = this.$_.findWhere(tariff.childTariffSettings, { userId: partner.id })?.discount || 0;
            this.updateCashBack = this.$_.findWhere(tariff.childTariffSettings, { userId: partner.id })?.cashback || 0;
            this.typeId = typeId;
            if (this.isLandingUserType(partner.typeId)) {
                data.landingTariffId = this.$_.findWhere(tariff.childTariffSettings, { userId: partner.id })?.id || 0;
            }

            this.updateTariffData = data;

            //TODO нужно будет с модальным окном решить что то, bootstrap vus не работает из-за конфиликта с простым bootstrap
            $('#updateCommissionModal').modal('show');
        },
        changeStatusTariff(event, partner, tariff) {
            if(tariff.gap !== null){
                if(tariff.commission <  this.getPartnerCommission(tariff.childTariffSettings, partner.id)){
                    event.target.checked = false;
                    this.$alert('Комісія субагента повинна бути менше ніж агентська');
                }else{
                    let data = {
                        partnerId: partner.id,
                        isActive: event.target.checked,
                        tariffId: tariff.id,
                    };
                    updateUserTariff(data)
                        .then(response => {
                            if (response.data === true) {
                                this.refreshCompaniesList();
                            }
                        })
                }
            }else{
                event.target.checked = false;
                this.$alert('Зазор не встановлен!');
            }
        },
        isShowCommission(tariff) {
            return !!! tariff?.tariff_user_setting?.owner_id || this.isPartner;
        },
        isShowAddTariff() {
          return this.partnersList && this.partnersList.length > 0;
        },
        getPartnerDiscount(tariffs, partnerId) {
            return this.$_.findWhere(tariffs,{ userId: partnerId })?.discount || 0;
        },
        getPartnerCashBack(tariffs, partnerId) {
            return this.$_.findWhere(tariffs,{ userId: partnerId })?.cashback || 0;
        },
        getPartnerCommission(tariffs, partnerId) {
            return this.$_.findWhere(tariffs,{ userId: partnerId })?.commission || 0;
        },
        isCheckPartner(partnerId,childTariffs) {
            return this.$_.findWhere(childTariffs,{ userId: partnerId })?.isActive || false;
        },
        addTariff(company) {
            let newTariff = { companyId:company.tariffs[0].id, companyType: company.companyType, companyName: company.tariffs[0].company_info.public_name };
            this.$store.commit('SET_NEW_TARIFF', newTariff);
            this.$router.push({name: 'addTariff'});
        },
        isShowTariff(tariff) {
            return tariff?.id;
        },
        getFranchise(tariff) {
            return tariff.franchise ? tariff.franchise.join() : '-';
        },
        getCoverage(tariff){
            return tariff.coverage ? tariff.coverage.join() : '-';
        },
        getOkt(tariff, companyType) {
            if (companyType === 'morphBusCompany') {
                return '-'
            }

            let otkType = '';
            _.each(tariff.otkType, (index) => {
                if (otkType) {
                    otkType += ', ';
                }

                otkType += this.$_.findWhere(this.$store.getters.OTK_TYPES_LIST, { id: index }).name;
            });

            return otkType;
        },
        getAutoType(tariff) {
            return tariff.autoCategoryType ? tariff.autoCategoryType.join() : '-';
            // let type = '';
            // _.each(tariff.autoCategoryType, (index) => {
            //     if (type) {
            //         type += '<br>';
            //     }
            //
            //     type +=  this.$_.findWhere(this.$store.getters.AUTO_CATEGORIES_LIST, { id: index }).name;
            // });
            //
            // return type;
        },
        getPeriodType(tariff) {

            let periodType = '';
            _.each(tariff.period, (index) => {
                if (periodType) {
                    periodType += ', ';
                }
                periodType +=  this.$_.findWhere(this.$store.getters.PERIOD_TYPES, { id: index }).name;
            });

            return periodType;

           // return tariff.period ? tariff.period.join() : '-';
        },
        getProgrammType(tariff) {

            let programmType = '';
            _.each(tariff.programms, (index) => {
                if (programmType) {
                    programmType += ', ';
                }
                programmType +=  index;//this.$_.findWhere(this.$store.getters.PERIOD_TYPES, { id: index }).name;
            });

            return programmType;

           // return tariff.period ? tariff.period.join() : '-';
        },
        getCommission(tariff) {
            return tariff.commission;
        },
        getUserType(tariff, companyType) {
            let userType = '';
            _.each(tariff.userType, (index) => {
                if (userType) {
                    userType += ', ';
                }

                userType +=  this.$_.findWhere(this.$store.getters.USER_TYPES_TARIFF_LIST, { id: index }).name;
            });

            return userType;
        },
        getZone(tariff) {
            return tariff.osagoZone? tariff.osagoZone.join() : '-';
        },

        filterStatusWrapp() {
            this.activeClassWrapp = !this.activeClassWrapp;
        },
        getError(key) {
            return this.errors[key][0]; //return first error
        },
        hasError(key) {
            return _.has(this.errors, key);
        },
        setFilterParams:function(params){
            this.$refs.filter.listQuery.companyTypes = params;
        },
        getQuery: function () {
           return {...this.$refs.filter.listQuery};
        },
        changeCompanySettings() {
            if (!this.percent > 0) {
                return false;
            }

            this.errors = null;
            updateCompanySettings(this.editCompany.id, this.partner.id, this.percent, this.statusCompany)
                .then(response => {
                    if (response?.data?.data?.id) {
                        this.refreshCompaniesList();
                        this.toCompaniesList();
                        this.$alert('Дані вдало оновлені');
                    }
                })
                .catch(e => {
                    this.errors = e.response.data.data;

                    return false;
                })
                .finally(() => {
                    //Костыль, потом нужно его убрать. При повторном просмотре ошибки должны отсутствовать!
                    setTimeout(() => {
                        this.errors = null;
                    },2000);
                });
        },
        toCompaniesList() {
            this.updateCompany = false;
            this.showCompanies = true;
        },
        fetchPartnersList() {
            return this.$store.dispatch('GET_ALL_PARTNERS');
        },
        refreshCompaniesList(isLoad = false, params = null) {
            this.isLoad = isLoad;
            getCompanySettingsList(params)
                .then(response => {
                    if (response?.data?.data) {
                        this.isLoad = false;

                        this.companiesList = response.data.data;
                    }
                });
        },
        initList: async function (isLoad = true) {

            let companyTypes = [];

            if(this.getQuery().companyTypeId){
                this.getQuery().companyTypeId.forEach((item) => {
                    companyTypes.push(this.$_.findWhere(this.$store.getters.START_COMPANY_TYPE, { id: item }));
                });
                this.setFilterParams(companyTypes);
            }
            this.refreshCompaniesList(isLoad, this.getQuery());
        },
    }
}
</script>
